import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const SearchTrend = ({ show, handleClose }) => {
  const [results, setResults] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [error, setError] = useState(null);
  const [validationMessage, setValidationMessage] = useState('');

  const fetchSearchTrend = async () => {
    const keywordArray = keywords.split(',').map((kw) => kw.trim()).filter(Boolean);

    // 입력된 검색어가 유효한 개수인지 확인
    if (keywordArray.length < 2) {
      setValidationMessage('검색어가 부족합니다. 2개 이상 입력해주세요.');
      return;
    } else if (keywordArray.length > 5) {
      setValidationMessage('검색어는 최대 5개까지만 입력할 수 있습니다.');
      return;
    } else {
      setValidationMessage('');
    }

    try {
      const response = await axios.post('https://reciperecom.store/api/search-trend', {
        keywords,
        gender,
        ages: age ? [age] : [],
      });
      setResults(response.data.results || []);
      setError(null);
    } catch (error) {
      console.error('API 요청 오류:', error);
      setError('트렌드 데이터를 불러오는 중 오류가 발생했습니다.');
    }
  };

  const renderChart = () => {
    if (results.length === 0) return null;

    const labels = results.map((result) => result.title);
    const datasets = [{
      label: 'NAVER 검색량',
      data: results.map((result) => result.data.reduce((sum, item) => sum + item.ratio, 0) / result.data.length),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    }];

    const data = {
      labels,
      datasets,
    };

    return <Bar data={data} options={{ responsive: true }} />;
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>메뉴 검색량 조회</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formKeywords">
            <Form.Label>검색어 (쉼표로 구분하여 입력)</Form.Label>
            <Form.Control
              type="text"
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              placeholder="메뉴를 입력해주세요."
            />
          </Form.Group>
          {validationMessage && (
            <Alert variant="warning" className="mt-2">
              {validationMessage}
            </Alert>
          )}
          <Form.Group controlId="formGender" className="mt-3">
            <Form.Label>성별</Form.Label>
            <Form.Control as="select" value={gender} onChange={(e) => setGender(e.target.value)}>
              <option value="">전체</option>
              <option value="m">남성</option>
              <option value="f">여성</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formAge" className="mt-3">
            <Form.Label>연령대</Form.Label>
            <Form.Control
              as="select"
              value={age}
              onChange={(e) => setAge(e.target.value)}
            >
              <option value="">전체</option>
              <option value="10">10대</option>
              <option value="20">20대</option>
              <option value="30">30대</option>
              <option value="40">40대</option>
              <option value="50">50대</option>
              <option value="60">60대 이상</option>
            </Form.Control>
          </Form.Group>
          <Button variant="primary" onClick={fetchSearchTrend} className="mt-3">
            조회
          </Button>
        </Form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <div className="mt-4">{renderChart()}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchTrend;
